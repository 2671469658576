<template>
  <div>
    <div class="content1">
      <div class="box">
        <div class="caption">{{ Information.article.title }}</div>
        <div class="schedule">{{ Information.publishTime | FILTER_FORMAT_TIME( "YYYY-MM-DD" )}}</div>
        <div class="horizontal_line"></div>
        <div class="details" v-html="Information.article.content"></div>
      </div>
    </div>
    <my-foot @changePage="changePage" id="anchor_3" />
  </div>
</template>

<script>
import myFoot from '@/components/myFoot/index.vue'
import { getInformation } from '@/web/topublisher.js'
export default {
  name: 'detaillist',
  data() {
    return {
      newsId: this.$route.query.newsId,
      newsTitle: this.$route.query.newsTitle,
      Information: {
          article:{
              title:""
          }
      },
    }
  },
  components: {
    myFoot,
  },
  created() {
    getInformation({ newsId: this.newsId,newsTitle: this.newsTitle}).then((res) => {
      console.log(res)
      this.Information = res
    })
  },
  mounted() {},
  methods: {
    changePage(i) {
      this.$store.commit('changePage', i)
    },
  },
}
</script>

<style lang="scss" scoped>
.content1{
  width: 100%;
  min-height: 1344px;
  background: url('../../assets/img/list/1.png') center no-repeat;
  background-size: cover;
  padding: 14px 0 30px 0;
  .box {
    width: 1200px;
    min-height: 613px;
    margin: 0 auto;
    // padding: 34px 60px 60px 60px;
    background: #d8d8d8 linear-gradient(180deg, #f7f4e9 0%, #eed8af 100%);
    .caption {
      font-size: 20px;
      font-weight: 600;
      color: #333333;
      line-height: 20px;
      text-align: center;
      padding-top: 34px;
    }
    .schedule {
      text-align: center;
      font-size: 14px;
      margin-top: 24px;
      font-weight: 400;
      color: #333333;
      line-height: 14px;
    }
    .horizontal_line {
      width: 100%;
      height: 1px;
      margin-top: 24px;
      background: #c8ab7d;
      opacity: 0.58;
    }
    .details {
      width: 100%;
      min-height: 613px;
      top: 14px;
      margin: 0 auto;
      padding: 34px 60px 60px 60px;
    }
  }
}
</style>

